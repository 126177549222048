import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Hero from '../../organism/Hero/Hero';
import Card from '../../atoms/Card/Card';
import Tabs from '../../atoms/Tabs/Tabs'
import Heading from '../../atoms/Heading/Heading';
import Content from '../../molecules/Content/Content';
import Section from '../../molecules/Section/Section';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import AirportForm from '../../organism/AirportForm';
import Faq from '../../organism/Faq/Faq';
import MediaQuery from 'react-responsive'
import { Helmet } from 'react-helmet';

import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';

class AirportShuttle extends Component {
  componentDidMount() {
    const { getArea, getProducts, setProductId, resetAirportShuttlePayload, location } = this.props;

    if (!(location.state && location.state.back)) {
      resetAirportShuttlePayload();
    }
    getArea();
    getProducts().then(data => setProductId(Number(data[1].id)));
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Airport Shuttle - Go Rental</title>
          <meta name="description" content="Whether you're travelling from-or-to Medan KNO airport, pre-book your airport transfer now! We will make sure you reach your destination safely & on time." />
        </Helmet>
        <Hero />
        <Content
          background="grey"
          isOverlapping
        >
          <Section>
            {/* DESKTOP VERSION */}
            <MediaQuery minDeviceWidth={960}>
              <Flexbox>
                <FlexboxItem columnClass="col-sm-6">
                  <Card colorWhite>
                    <AirportForm />
                  </Card>
                </FlexboxItem>
                <FlexboxItem columnClass="col-sm-6">
                  <Faq />
                </FlexboxItem>
              </Flexbox>
            </MediaQuery>
            {/* END DESKTOP VERSION */}

            {/* MOBILE VERSION */}
            <MediaQuery maxDeviceWidth={959}>
              <div className="u-mb-20 u-align-center">
                <Heading tag="h2">
                  Book Airport Transfer
                </Heading>
              </div>
              <Card colorWhite isBleed>
                <Tabs color="white">
                  <div labelName="Booking">
                    <AirportForm />
                  </div>
                  <div labelName="FAQ">
                    <Faq />
                  </div>
                </Tabs>
              </Card>
            </MediaQuery>
            {/* END MOBILE VERSION */}

          </Section>
        </Content>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ area, products, airportShuttleBooking }) => ({
  area: area.data,
  products: products.data,
  payload: airportShuttleBooking.payload,
});

const { getArea } = actions.areaAction;
const { getProducts } = actions.productsAction;
const { resetAirportShuttlePayload, setProductId } = actions.airportShuttleBookingAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  getArea,
  getProducts,
  resetAirportShuttlePayload,
  setProductId,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AirportShuttle)
