import dayjs from 'dayjs';
import React, { Component, Fragment } from 'react'
import Text from '../../atoms/Text/Text';
import Flexbox from '../../molecules/Flexbox/Flexbox';
import FlexboxItem from '../../atoms/FlexboxItem/FlexboxItem';
import Button from '../../atoms/Button/Button';
import CheckBox from '../../atoms/CheckBox/CheckBox';
import Separator from '../../atoms/Separator/Separator';
import TextField from '../../atoms/TextField/TextField';
import SelectBox from '../../atoms/SelectBox/SelectBox';
import RadioImage from '../../atoms/RadioImage/RadioImage';
import Counter from '../../atoms/Counter/Counter';
import DateTime from '../../molecules/DateTime/DateTime';
import DateTimeNative from '../../molecules/DateTimeNative/DateTimeNative';
import Outline from '../../molecules/Outline/Outline';
import Shimmer from '../../atoms/Shimmer/Shimmer';
import Preloader from '../../atoms/Preloader/Preloader';
import Callout from '../../atoms/Callout/Callout';
import StandardCar from './img/standard.png'
import PremiumCar from './img/premium.png'
import LuxuryCar from './img/luxury.png'
import RadioBlock from '../../atoms/RadioBlock/RadioBlock'
import RadioBlockGroup from '../../molecules/RadioBlockGroup/RadioBlockGroup'
import Modal from '../../molecules/Modal/Modal'
import ListStyle from '../../molecules/ListStyle/ListStyle'
import Fuel from './img/modal/fuel.svg'
import Meal from './img/modal/meal.svg'
import Accomodation from './img/modal/accomodation.svg'
import Overtime from './img/modal/time.svg'
import OutOfTown from './img/modal/outoftown.svg'
import './style.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router';
import Formsy from 'formsy-react';
import FormsyTextField from '../../atoms/FormsyTextField';
import { idr } from 'api/helpers/common';

const DATE_FORMAT = 'DD MMMM YYYY';

class CarRentalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      priceText: "excludes fuel, toll, parking and driver's meal and accomodation",
      modalActiveClass: '',
      mapActiveClass: ''
    };
    this.showInfoModal = this.showInfoModal.bind(this);
    this.hideInfoModal = this.hideInfoModal.bind(this);
  }

  showInfoModal() {
    this.setState({
      modalActiveClass: 'is-active',
    });
  }

  hideInfoModal() {
    this.setState({
      modalActiveClass: '',
    })
  }

  componentDidMount() {
    const { history } = this.props;
    const { state } = history.location;

    if (state && state.back) {
      this.props.clearOutOfTown();
    } else {
      this.props.resetState();
    }

    setTimeout(() => {
      this.props.calculatePrice(this.pricePayload(this.props.carRentalBooking.payload));
    }, 0);
  }

  pricePayload(payload) {
    return {
      pickupAddress: payload.pickupAddress,
      pickupDistrictID: payload.pickupDistrictID,
      startRentalDate: payload.startRentalDate,
      dropoffAddress: payload.dropoffAddress,
      dropoffDistrictID: payload.dropoffDistrictID,
      numberOfCars: payload.numberOfCars,
      rentalDuration: payload.rentalDuration,
      rentalHours: payload.rentalHours,
      allInPackage: payload.allInPackage,
      productID: payload.productID,
      promoCode: payload.promoCode,
      paymentMethod: payload.paymentMethod,
      outOfTown: payload.outOfTown,
    };
  }

  updatePayload(payload) {
    this.props.setState(payload);
    this.props.calculatePrice(this.pricePayload(this.props.carRentalBooking.payload));
  }

  toggleDifferentAddress() {
    this.props.toggleDifferentEndAddress();
    this.updatePayload(['dropoffAddress', null]);
    this.updatePayload(['dropoffDistrictID', null]);
  }

  toggleAllInPackage() {
    const { allInPackage } = this.props.carRentalBooking.payload;
    this.props.setState(['allInPackage', !allInPackage]);
    this.updatePayload(['outOfTown', []]);
    if (allInPackage) {
      this.setState({
        priceText: "excludes fuel, toll, parking and driver's meal and accomodation"
      })
    } else {
      this.setState({
        priceText: "includes fuel, toll, parking and driver's meal and accomodation"
      })
    }
  }

  render() {
    const {
      district,
      products,
      carRentalBooking,
      setPickupDate,
    } = this.props;
    const {
      isCalculating,
      isValidPayload,
      startRentalDate,
      payload,
      totalPrice,
      isDifferentEndAddress,
    } = carRentalBooking;
    const endBooking = dayjs(startRentalDate).add(payload.rentalDuration - 1, 'days');
    const productImages = [
      // StandardCar,
      PremiumCar,
      LuxuryCar,
    ];
    const pickupZone = district.filter(dis => dis.id === payload.pickupDistrictID)[0] ? district.filter(dis => dis.id === payload.pickupDistrictID)[0].zoneID : null
    const dropoffZone = district.filter(dis => dis.id === payload.dropoffDistrictID)[0] ? district.filter(dis => dis.id === payload.dropoffDistrictID)[0].zoneID : null
    const requiredFormat = /^08\d{7,11}$/;
    const isPhoneNumberValid = requiredFormat.test(payload.phoneNumber);
    return (
      <div className="g-airportform">
        <Preloader isActive={false} />
        <Preloader
          isActive={false}
          displayImage={true}
          title="Preloader Message"
          onClick={() => {}}
        />
        <Formsy>
          <div className="g-airportform__body">
            <div className="u-mb-30">
              <Text size="medium">Fill up this information to rent a car</Text>
            </div>
            <div className="u-mb-30">
              <TextField
                name="name"
                type="text"
                placeholder="Name"
                description=""
                value={payload.fullname}
                onChange={({ target }) => this.updatePayload(['fullname', target.value])}
                required
              />
            </div>
            <div className="u-mb-30">
              <FormsyTextField
                name="email"
                type="email"
                placeholder="Email"
                description=""
                value={payload.email}
                onChange={({ target }) => this.updatePayload(['email', target.value])}
                validations="isEmail"
                validationError="Input valid email"
                required
              />
            </div>
            <div className="u-mb-30">
              <FormsyTextField
                type="number"
                name="mobilePhoneNumber"
                placeholder="Mobile Phone Number"
                description=""
                value={payload.phoneNumber}
                onChange={({ target }) => this.updatePayload(['phoneNumber', target.value])}
                validations={{
                  matchRegexp: /^08\d{7,11}$/
                }}
                validationErrors={{
                  matchRegexp: 'Mohon mengisi nomor telepon dengan format 08xxx'
                }}
                required
              />
            </div>
            <div className="u-mb-30">
              <DateTime
                date={startRentalDate}
                onChange={date => setPickupDate(date)}
              />
              <DateTimeNative
                date={startRentalDate}
                setDate={date => setPickupDate(date)}
              />
            </div>
            <div className="u-mb-30">
              <Flexbox>
                <FlexboxItem columnClass="col-xs-6 col-sm-7">
                  <Text size="medium">Rental duration (in days)</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-6 col-sm-5" className="end-sm g-airportform__grid-radio-group">
                  <Counter
                    min="1"
                    max="14"
                    total={payload.rentalDuration}
                    onChange={value => this.updatePayload(['rentalDuration', value])}
                  />
                  <Text grey size="desc">Rental ends in {endBooking.format(DATE_FORMAT)}</Text>
                </FlexboxItem>
              </Flexbox>
            </div>
            <div className="u-mb-30">
              <Flexbox className="middle-xs middle-sm">
                <FlexboxItem columnClass="col-xs-6 col-sm-7">
                  <Text size="medium">Rental hours per day</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-6 col-sm-5" className="g-airportform__grid-radio-group">
                  <RadioBlockGroup>
                    <RadioBlock
                      id="5"
                      name="rental-hours"
                      title="5 hours"
                      value="5"
                      checked={payload.rentalHours === 5}
                      onChange={() => this.updatePayload(['rentalHours', 5])}
                    />
                    <RadioBlock
                      id="8"
                      name="rental-hours"
                      title="8 hours"
                      value="8"
                      checked={payload.rentalHours === 8}
                      onChange={() => this.updatePayload(['rentalHours', 8])}
                    />
                  </RadioBlockGroup>
                </FlexboxItem>
              </Flexbox>
            </div>
            <div className="u-mb-30">
              <SelectBox
                value={payload.pickupDistrictID || ''}
                onChange={({ target }) => this.updatePayload(['pickupDistrictID', Number(target.value)])}
              >
                <option value="" disabled>Choose Pick-Up District</option>
                  <optgroup label="In-Town">
                    {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 1)).map(({ id, district, zoneID }) => (
                      <option key={id} value={id}>{district}</option>
                    ))}
                  </optgroup>
                  { payload.rentalHours === 8 && (
                    <optgroup label="Zone 1 (extra Rp 150.000 / day)">
                    {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 2)).map(({ id, district, zoneID }) => (
                      <option key={id} value={id}>{district}</option>
                    ))}
                    </optgroup>
                  )}
                  { payload.rentalHours === 8 && (
                    <optgroup label="Zone 2 (extra Rp 200.000 / day)">
                    {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 3)).map(({ id, district, zoneID }) => (
                      <option key={id} value={id}>{district}</option>
                    ))}
                    </optgroup>
                  )}
              </SelectBox>
            </div>
            <div className="u-mb-30">
              <TextField
                type="text"
                name="address"
                placeholder="Pick-up Address Detail"
                description=""
                value={payload.pickupAddress}
                onChange={({ target }) => this.updatePayload(['pickupAddress', target.value])}
                required
              />
            </div>
            <div className="u-mb-30">
              <CheckBox
                title="My pickup and dropoff address are the same"
                description=""
                name="additional-drop"
                checked={!isDifferentEndAddress}
                onChange={() => this.toggleDifferentAddress()}
              />
            </div>
            {isDifferentEndAddress && (
              <React.Fragment>
                <div className="u-mb-30">
                  <SelectBox
                    value={payload.dropoffDistrictID || ''}
                    onChange={({ target }) => this.updatePayload(['dropoffDistrictID', Number(target.value)])}
                  >
                    <option value="" disabled>Choose Drop-off District</option>
                      <optgroup label="In-Town">
                        {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 1)).map(({ id, district, zoneID }) => (
                          <option key={id} value={id}>{district}</option>
                        ))}
                      </optgroup>
                      { payload.rentalHours === 8 && (
                        <optgroup label="Zone 1 (extra Rp 150.000 / day)">
                        {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 2)).map(({ id, district, zoneID }) => (
                          <option key={id} value={id}>{district}</option>
                        ))}
                        </optgroup>
                      )}
                      { payload.rentalHours === 8 && (
                        <optgroup label="Zone 2 (extra Rp 200.000 / day)">
                        {(payload.rentalHours === 5 ? district.filter(dis => dis.zoneID === 1) : district.filter(dis => dis.zoneID === 3)).map(({ id, district, zoneID }) => (
                          <option key={id} value={id}>{district}</option>
                        ))}
                        </optgroup>
                      )}
                  </SelectBox>
                </div>
                <div className="u-mb-30">
                  <TextField
                    type="text"
                    name="address"
                    placeholder="Drop-Off Address Detail"
                    description=""
                    value={payload.dropoffAddress || ''}
                    onChange={({ target }) => this.updatePayload(['dropoffAddress', target.value])}
                    required
                  />
                </div>
              </React.Fragment>
            )}
            <div className="u-mb-30">
              <Separator />
            </div>
            <div className="u-mb-30">
              <div className="u-mb-8">
                <Outline>
                  {products.slice(1,3).map(({ id, productType, carRentalPrice }, index) => (
                    <RadioImage
                      key={id}
                      name="choose-car"
                      id={`${id}`}
                      value={id}
                      checked={id === payload.productID}
                      onChange={({ target }) => this.updatePayload(['productID', Number(target.value)])}
                      imageUrl={productImages[index]}
                      title={`${productType}`}
                      description={idr(carRentalPrice)}
                    />
                  ))}
                </Outline>
              </div>
              <Text grey>
                <strong>Capacity</strong>: Max 6 passengers and 2 baggages/ car
              </Text>
            </div>
            <div className="u-mb-30">
              <Flexbox className="middle-xs middle-sm">
                <FlexboxItem columnClass="col-xs-6 col-sm-6">
                  <Text size="medium">Number of Car(s)</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-6 col-sm-6" className="end-xs end-sm">
                  <Counter
                    min="1"
                    max="5"
                    total={payload.numberOfCars}
                    onChange={value => this.updatePayload(['numberOfCars', value])}
                  />
                </FlexboxItem>
              </Flexbox>
            </div>
            <div className="u-mb-30">
              <Callout
                isInfo
                onClick={this.showInfoModal}
              >
                <Text size="medium" bold>Important to know</Text>
                Total price doesn’t cover these expenses
              </Callout>
            </div>
            { payload.rentalHours === 8 && pickupZone === 1 && (this.state.toggleDifferentAddress || dropoffZone === 1) && (
              <div className="u-mb-30">
                <CheckBox
                  title="I want In-Town All in One Rp 150.000/day"
                  description="Fuel, tolls, parking, and driver’s meals are included"
                  name="additional-drop"
                  checked={payload.allInPackage}
                  onChange={() => this.toggleAllInPackage()}
                />
              </div>
            )}
            <div className="u-mb-30">
              <Separator />
            </div>
            <div className="u-mb-30">
              <Flexbox>
                <FlexboxItem columnClass="col-xs-5 col-sm-6">
                  <Text bold size="medium">Total price</Text>
                </FlexboxItem>
                <FlexboxItem columnClass="col-xs-7 col-sm-6">
                  <div className="u-align-right">
                    { !!isCalculating && (
                      <Shimmer style={{ width: '120px', height: '26px' }} isActive={true} />
                    )}
                    { !isCalculating && (
                      <Text size="medium" bold>{idr(totalPrice.amount || 0)}</Text>
                    )}
                    <Text>{payload.rentalHours === 5 ? "includes fuel, toll, parking and driver's meal and accomodation" : this.state.priceText}</Text>
                  </div>
                </FlexboxItem>
                {!isCalculating && totalPrice.details && !!totalPrice.details.length && (
                  <FlexboxItem columnClass="col-xs-12 col-sm-12">
                    <Separator className="u-mt-30" />
                    <div className="u-mt-30" style={{ textAlign: 'right' }}>
                      {totalPrice.details.map((dt, index) =>(
                        <div key={index}>{dt.text}</div>
                      ))}
                    </div>
                  </FlexboxItem>
                )}
                {!!isCalculating && (
                  <FlexboxItem columnClass="col-xs-12 col-sm-12">
                    <Separator className="u-mt-30" />
                    <div className="u-mt-30" style={{ textAlign: 'right' }}>
                      <Shimmer style={{ width: '70%', height: '18px' }} isActive={true} />
                      <Shimmer style={{ width: '50%', height: '18px', marginTop: '2px' }} isActive={true} />
                    </div>
                  </FlexboxItem>
                )}
              </Flexbox>
            </div>
            { isValidPayload && !payload.allInPackage && payload.rentalHours === 8 && (
              <div className="u-mb-30">
                <Callout
                  onClick={() => this.props.history.push({
                    pathname: '/manage-rent',
                  })}
                >
                  This rent is only for in-town trip. Please manage add-ons for Out-of-Town trip
                </Callout>
              </div>
            )}
            <div className="u-mb-30">
              {!isCalculating && isValidPayload && isPhoneNumberValid &&(
                <Button
                  text="Proceed to payment"
                  onClick={() => this.props.history.push({
                    pathname: '/payment-method',
                    from: 'car-rental',
                  })}
                />
              )}
              {(!!isCalculating || !isValidPayload || !isPhoneNumberValid) && (
                <Button
                  text={isCalculating ? 'Loading, please wait...' : 'Proceed to payment' }
                  disabled={true}
                />
              )}
            </div>
          </div>
        </Formsy>

        {/* modal info */}
        <Modal
          sizeSmall
          hideCloseButton
          className={`${this.state.modalActiveClass}`}
          handleClose={this.hideInfoModal}
        >
          {this.state.modalActiveClass &&
            <Fragment>
              <div className="u-mb-25">
                <Text size="medium" bold>Other Expenses</Text>
              </div>
              <ListStyle
                icon={Fuel}
                title="Fuel"
                description="Fill the tank according to your needs. Return the fuel as received."
                className="u-mb-25"
              />
              <ListStyle
                icon={Meal}
                title="Driver's Meal"
                description="To cover your driver's meal. Pay directly to driver Rp 50.000/day for in-town trip. Rp 75.000/day for Out-of-Town trip"
                className="u-mb-25"
              />
              <ListStyle
                icon={Accomodation}
                title="Driver's Accomodation"
                description="To cover driver’s overnight stay for Out-of-Town trip. Pay directly to driver. From Rp 150.000/Night(s)"
                className="u-mb-25"
              />
              <ListStyle
                icon={Overtime}
                title="Overtime"
                description="Pay directly to driver. Starting from Rp 50.000/hour"
                className="u-mb-25"
              />
              <ListStyle
                icon={OutOfTown}
                title="Out-Of-Town Usage"
                description="Additional fee applies if you travel to Zone 1 or Zone 2"
                className="u-mb-25"
              />
              <Button
                onClick={this.hideInfoModal}
                text="Ok, I Understand"
              />
            </Fragment>
          }
        </Modal>
        {/* modal info */}
      </div>
    )
  }
}

const mapStateToProps = ({ district, products, carRentalBooking }) => ({
  district: district.data,
  products: products.data,
  carRentalBooking,
});

const {
  calculatePrice,
  clearOutOfTown,
  resetState,
  setState,
  setPickupDate,
  setPickupTime,
  toggleDifferentEndAddress,
} = actions.carRentalBookingAction;

const mapDispatchToProps = dispatch => bindActionCreators({
  calculatePrice,
  clearOutOfTown,
  resetState,
  setState,
  setPickupDate,
  setPickupTime,
  toggleDifferentEndAddress,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CarRentalForm));
